@import "./../../App.scss";

.banner-check-policy {
  background-color: rgba(map-get($theme-colors, "danger"), 0.7);
  width: calc(100% - 200px);
  margin-left: 200px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  padding: 16px;
  border: 2px dashed #984f4f;
  a {
    color: #984f4f;
    transition: 0.2s;
    .btn{
      margin-top: 0;
      font-size: 14px;
      background-color: #984f4f !important;
      background: inherit;
      color: map-get($theme-colors, "white");
      padding: 3px 20px !important;
      margin-left: 15px;
      font-weight: 600;
      margin-top: -3px;
    }
    &:hover{
      text-decoration: none;
      color: map-get($theme-colors, "white");
    }
  }
  @media only screen and (max-width: 991px){
    width: 100%;
    margin-left: 0;
  }
}
