@import "./../../App.scss";

.recharts-legend-item {
  font-size: 14px;
}

.recharts-tooltip-item {
  font-size: 12px;
  display: block;
}
