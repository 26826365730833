@import "./../../App.scss";

.check-required {
  margin-top: 20px;
  label {
    background-color: map-get($theme-colors, "warning");
    color: map-get($theme-colors, "white") !important;
    font-weight: 700;
    border-radius: 20px;
    padding: 3px 8px;
    font-style: inherit !important;
  }
}

.btn-delete {
  background: inherit !important;
  background-color: map-get($theme-colors, "danger") !important;
  color: map-get($theme-colors, "white");
  margin-top: 0 !important;
  margin-right: 8px;
  margin-bottom: 8px;
  font-size: 13px;
  padding: 6px 10px;
  font-weight: 700;
  text-transform: uppercase;
  border: 0;
  border-radius: 20px;
  font-weight: 600;
  -webkit-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
}

.badge {
  background-color: map-get($theme-colors, "warning");
  margin-left: 16px;
  padding: 6px 8px;
}

.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
  input {
    width: 100%;
    padding: 6px 12px;
  }
}