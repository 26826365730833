@import "./../../App.scss";

.dashboard {
  #api-key {
    color: map-get($theme-colors, "black");
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
  }
  .progress {
    background-color: #D0D0D0;
    border-radius: 10px;
    &.danger {
      .progress-bar {
        background-color: map-get($theme-colors, "danger");
      }
    }
    .progress-bar {
      background-color: map-get($theme-colors, "primary");
    }
  }
}
