@import "./../../App.scss";

.btn-lang {
  button {
    border: 0;
    font-weight: 600;
    background-color: transparent;
    color: map-get($theme-colors, "secondary");
    transition: 0.2s ease-in-out;
    &:focus,
    &:hover,
    &.selected {
      color: map-get($theme-colors, "primary");
      box-shadow: none;
      outline: 0;
    }
  }
}
