@import './../../App.scss';

.burger-menu{
    display: none;
    .bm-burger-button{
        width: 30px;
        position: fixed;
        top: 16px;
        left: 30px;
        button{
            width: 30px !important;
            height: 30px !important;
            &:focus{
                outline: 0;
                box-shadow: none;
            }
        }
    }
    @media only screen and (max-width: 991px){
        display: block;
    }
}