@import './../../App.scss';

nav{
    background-color: map-get($theme-colors, "white");
    overflow: hidden;
    height: 100%;
    padding: 60px 0px 30px 30px;
    text-align: left;
    a{
        display: block;
        position: relative;
        padding-top: 8px;
        padding-bottom: 8px;
        margin-bottom: 21px;
        overflow: hidden;
        color: map-get($theme-colors, "primary-contrast");
        font-family: $Spectral;
        font-weight: 800;
        div{
            width: 100%;
            padding: 8px 16px;
            color: map-get($theme-colors, "primary");
            border-bottom: 2px solid map-get($theme-colors, "secondary");
            transition: 0.2s ease-in-out;
        }
        &:hover,
        &.active{
            text-decoration: none;
            div{
                transform: translateX(20px);
                border-bottom: 2px solid map-get($theme-colors, "primary");
            }
        }
    }
}

.nav-desktop{
    position: relative;
    width: $menu-size;
    z-index: 1000;
    nav{
        position: fixed;
        // float: left;
        top: 0;
        left: 0;
        height: 100vh;
        width: $menu-size;
    }
    @media only screen and (max-width: 991px){
        display: none; 
     }
}