@import "./../../App.scss";

.chips-input {
  border: none !important;
  font-family: $OpenSans !important;
}

.chips-container {
  border: 0;
  border-bottom: 2px solid map-get($theme-colors, "primary");
  margin-bottom: 0px !important;
  &:before {
    content: none !important;
  }
  &:after {
    background-color: map-get($theme-colors, "primary-contrast") !important;
  }
}
