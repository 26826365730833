@import "./../../App.scss";

.info-cont{
  position: relative;
  background-color: map-get($theme-colors, "background");
  -webkit-box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.12);
  -moz-box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.12);
  box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.12);
  padding: 40px 16px 40px 70px;
  max-width: 500px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  p{
    color: map-get($theme-colors, "primary");
    font-weight: 700;
    text-align: center;
    margin-bottom: 0;
  }
  div{
    position: absolute;
    background-color: map-get($theme-colors, "primary");
    left: 0;
    top: 0;
    height: 100%;
    width: 60px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    font-size: 30px;
    .fa-info-circle{
      color: map-get($theme-colors, "white");
    }
  }
}