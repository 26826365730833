@import './../../App.scss';

.error-page{
  padding-top: 100px;
  padding-bottom: 100px;
  h1{
    font-size: 80px;
    font-weight: 700;
  }
  p{
    font-size: 18px;
  }
  @media only screen and(max-width: 767px){
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
    .u-center-vertical{
      position: relative;
      top: inherit;
      transform: inherit;
    }
    img{
      margin-top: 40px;
      width: 350px;
    }
  }
}