// VARIABLES

$theme-colors: (
  "background": #f1fcfc,
  "black": #272343,
  "white": #ffffff,
  "grey": #dddddd,
  "primary": #272343,
  "primary-contrast": #f1fcfc,
  "secondary": #dec8fe,
  "border": #c9c9c9,
  "darkborder": #777777,
  "success": #8fc485,
  "danger": #d87373,
  "warning": #ed9b00,
);

$Spectral: "Spectral", serif;
$OpenSans: "Open Sans", sans-serif;

$menu-size: 200px;

//GENERAL

body {
  margin: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  font-family: $OpenSans;
  background-color: map-get($theme-colors, "background");
}

.corpo {
  position: absolute;
  right: 0;
  height: auto;
  width: calc(100% - 200px);
  padding: 0 32px 32px;
  float: left;
  overflow-y: scroll;
  @media only screen and (max-width: 991px) {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 0 16px 60px;
  }
}

//CENTRATURA ELEMENTI

.u-center-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.u-center-vertical {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.u-center-horizontal {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.u-center-horizontal-inner {
  display: table;
  margin: 0 auto;
}

// BACKGROUND

.u-bg-light {
  background-color: map-get($theme-colors, "white");
}

// CONT SHADOW - CARD

.card {
  background-color: map-get($theme-colors, "white");
  -webkit-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  border-radius: 0px;
  border: none;
  overflow: hidden;
  margin-bottom: 32px;
  &.login-card {
    margin-top: -130px;
    max-width: 600px;
    margin-bottom: 10px;
    .card-header {
      background-color: map-get($theme-colors, "white");
      border: none;
      text-align: center;
      padding-top: 30px;
      .card-title {
        font-size: 20px;
        color: map-get($theme-colors, "darkborder");
        font-weight: 500;
        span {
          color: map-get($theme-colors, "black");
          font-family: $Spectral;
          font-weight: 800;
          font-size: 25px;
        }
      }
    }
    .card-body {
      padding: 21px 40px !important;
      .btn {
        min-width: 200px;
      }
      .col-6:last-child {
        a {
          font-family: $OpenSans !important;
          color: map-get($theme-colors, "primary");
          text-decoration: underline;
        }
      }
    }
    .card-footer {
      padding: 16px 20px;
    }
    @media only screen and (max-width: 575px) {
      margin-top: -80px;
      .card-header {
        padding-bottom: 0;
        .card-title {
          font-size: 18px;
        }
      }
      .card-body {
        padding: 16px !important;
        .btn {
          margin-top: 0px;
          font-size: 16px;
        }
      }
    }
  }
  .card-header {
    background-color: map-get($theme-colors, "white");
    border: 0;
    .card-title {
      margin-bottom: 0;
      color: map-get($theme-colors, "black");
      font-weight: 700;
    }
  }
  .card-body {
    padding: 16px;
    &.no-padding {
      padding: 16px 0;
    }
  }
  .card-footer {
    background-color: map-get($theme-colors, "grey");
    border-top: 1px solid map-get($theme-colors, "darkborder");
    padding-top: 24px;
    padding-bottom: 24px;
    color: map-get($theme-colors, "darkborder");
    .row {
      .col-6 {
        &:first-child {
          font-weight: 700;
        }
        &:last-child {
          a {
            color: map-get($theme-colors, "primary");
            i {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}

.cont-white-shadow {
  background-color: map-get($theme-colors, "white");
  -webkit-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  padding: 30px 15px;
  border-radius: 20px;
}

// FORM E LABEL

form {
  .form-group {
    margin-bottom: 24px;
    label {
      color: map-get($theme-colors, "primary");
      font-family: $Spectral;
      font-size: 14px;
      font-weight: 700;
    }
    input,
    textarea,
    select {
      border: 0;
      border: 1px solid map-get($theme-colors, "primary");
      border-radius: 0;
      font-weight: 600;
      color: map-get($theme-colors, "black");
      background-color: transparent;
      &:focus {
        box-shadow: none;
        border-color: map-get($theme-colors, "secondary");
        background-color: transparent;
      }
    }
    .form-text {
      font-size: 10px;
      color: map-get($theme-colors, "grey");
    }
    .custom-control-label {
      font-family: $OpenSans;
      font-style: italic;
      a {
        color: map-get($theme-colors, "primary");
        text-decoration: underline;
      }
    }
    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: map-get($theme-colors, "secondary");
      background-color: map-get($theme-colors, "secondary");
    }
  }
}

.input-group-append {
  .btn {
    margin-top: 0;
    font-size: 14px;
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
  }
}

.add-consens-cont {
  .col-md-6 {
    border-bottom: 0 !important;
  }
}

.list-consens {
  ul {
    list-style: none;
    padding-left: 0;
    li {
      .badge {
        font-size: 10px;
      }
    }
  }
}

// BUTTON

.btn {
  background-color: map-get($theme-colors, "primary");
  color: map-get($theme-colors, "white");
  border: 0;
  border-radius: 0px;
  padding: 7px 25px !important;
  margin-top: 16px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 30px 15px;
  font-size: 18px;
  transition: 0.2s ease-in-out;
  &.danger {
    background: inherit;
    background-color: map-get($theme-colors, "danger");
  }
  &:hover,
  &:focus,
  &:active {
    opacity: 0.6;
    color: map-get($theme-colors, "white");
    outline: none;
    box-shadow: 0;
  }
  img {
    width: 25px;
    margin-right: 7px;
  }
}

.btn-radius {
  color: map-get($theme-colors, "primary");
  cursor: pointer;
  min-width: inherit !important;
  margin-top: 0;
  width: 50px;
  height: 50px;
  padding: 0px !important;
  border-radius: 50%;
  color: map-get($theme-colors, "white");
  img {
    width: 25px;
    margin-right: 0;
  }
}

.btn-back {
  font-size: 13px;
  margin-top: 0;
  margin-right: 15px;
  i {
    margin-right: 5px;
  }
}

// TEXT

h4 {
  font-family: $Spectral;
  color: map-get($theme-colors, "black");
  font-size: 21px;
  font-weight: 800;
  margin-bottom: 16px;
}

h5 {
  color: map-get($theme-colors, "black");
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 16px;
}

.description {
  color: #777777;
  font-size: 14px;
}
// MARGIN - PAGGING

.m-b-35 {
  margin-bottom: 35px;
}

// CONT-GREY

.cont-grey {
  background-color: map-get($theme-colors, "background");
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 20px;
  -webkit-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  .cont-grey-header {
    padding: 24px 16px;
    border-bottom: 1px solid map-get($theme-colors, "border");
    .col-6 {
      &:first-child {
        h6 {
          color: map-get($theme-colors, "primary");
          font-weight: 700;
          font-size: 18px;
          margin-bottom: 0;
        }
      }
      &:last-child {
        h6 {
          color: map-get($theme-colors, "primary-contrast");
          font-size: 18px;
          margin-bottom: 0;
        }
      }
    }
  }
  .cont-grey-body {
    padding: 16px;
    color: map-get($theme-colors, "black");
    p {
      margin-bottom: 0;
    }
  }
}

// TABLE

.data-table {
  thead {
    background-color: transparent;
    tr {
      th {
        color: map-get($theme-colors, "black");
        font-family: $Spectral;
        font-weight: 800;
        font-size: 18px;
      }
    }
  }
  tbody {
    tr {
      &:first-child {
        td {
          border-top: 0;
        }
      }
      td {
        color: map-get($theme-colors, "black");
      }
      &:hover {
        background-color: rgba(map-get($theme-colors, "secondary"), 0.1);
        cursor: pointer;
      }
    }
  }
  &.no-hover {
    tbody {
      tr {
        &:hover {
          background-color: inherit;
          cursor: inherit;
        }
      }
    }
  }
}

.form-control:disabled,
.custom-select:disabled {
  background-color: map-get($theme-colors, "white");
}

form {
  .form-group {
    .form-text.error-message {
      color: map-get($theme-colors, "danger") !important;
    }
  }
}

.hidden {
  display: none;
}
