@import "./../../App.scss";

.cont-pagination {
  margin-top: 20px;
  nav {
    background-color: transparent;
    padding: 0;
    ul {
      li {
        .page-link {
          margin-bottom: 0;
          color: map-get($theme-colors, "primary") !important;
          border: none;
          transition: 0.2s ease-in-out;
          background-color: transparent !important;
          font-weight: 600;
          font-size: 18px;
          &:hover,
          &:active,
          &:focus {
            background-color: transparent;
            color: map-get($theme-colors, "secondary") !important;
            outline: 0;
            box-shadow: none;
            transform: translateY(-3px);
          }
          &::before {
            border: none;
          }
        }
        &.active {
          .page-link {
            color: map-get($theme-colors, "secondary") !important;
          }
        }
      }
    }
  }
}
