@import './../../App.scss';

.sct-start{
  height: 300px;
  .header-logo{
    position: relative;
    height: auto;
    border: 4px solid map-get($theme-colors, "background");
    border-top: 0;
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
    padding-top: 30px;
    padding-bottom: 30px;
    img{
      max-height: 50px;
      width: 100%;
    }
  }
  @media only screen and (max-width: 575px){
    height: 220px;
    .header-logo{
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      padding-top: 20px;
      padding-bottom: 20px;
      img{
        max-height: 40px;
      }
    }
  }
  .position-lang{
    text-align: center;
    margin-top: 20px;
  }
}