@import './../../App.scss';

.header-crm{
  padding: 16px 32px;
  background-color: map-get($theme-colors, "white");
  margin-bottom: 24px;
  -webkit-box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.12);
  -moz-box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.12);
  box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.12);
  h2{
    color: map-get($theme-colors, "primary");
    font-size: 18px;
    line-height: 29px;
    font-weight: 700;
    margin-bottom: 0;
    font-family: $Spectral;
    font-weight: 800;
    img{
      width: 20px;
      margin-right: 8px;
    }
    span{
      color: map-get($theme-colors, "primary");
    }
  }
  .cont-header-element{
    width: max-content;
    float: right;
    padding-top: 3px;
    p{
      margin-bottom: 0;
      float: right;
      margin-right: 21px;
      span{
        font-weight: 700;
      }
    }
    .btn-logout{
      background-color: map-get($theme-colors, "danger") !important;
      color: map-get($theme-colors, "white");
      float: right;
      margin: 0;
      font-size: 14px;
      padding: 4px 16px !important;
      margin-top: -2px;
      margin-right: 21px;
      font-weight: 800;
      text-transform: uppercase;
      border: 0;
      border-radius: 0px;
      &:active,
      &:focus{
        outline: none;
        box-shadow: none;
      }
    }
    .btn-lang{
      float: right;
    }
  }
  @media only screen and (max-width: 991px){
    h2{
      text-align: center;
      margin-bottom: 8px;
    }
    .cont-header-element{
      float: inherit;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      p{
        display: none;
      }
    }
  }
}