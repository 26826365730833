@import "./../../App.scss";

.view-text-label {
  padding-top: 7px;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
  svg {
    margin-top: -4px;
    width: 24px;
  }
}
