@import "./../../App.scss";

.cont-version {
  border: 2px solid map-get($theme-colors, "primary");
  border-top: 0;
  margin-bottom: 16px;
  .header-version {
    position: relative;
    background-color: map-get($theme-colors, "primary");
    padding: 8px;
    width: 100%;
    h6 {
      margin-bottom: 0;
      color: map-get($theme-colors, "white");
      span {
        &.number-version {
          background-color: map-get($theme-colors, "white");
          color: map-get($theme-colors, "primary");
          border-radius: 20px;
          padding: 7.2px 14px;
          margin-right: 8px;
          font-weight: 700;
        }
        &.date {
          margin-left: 16px;
          font-weight: 700;
        }
      }
    }
    .btns {
      position: absolute;
      right: 0;
      top: 0px;
      .btn {
        margin-top: 6px;
        background: inherit;
        background-color: transparent !important;
        box-shadow: none;
      }
    }
    .btn-delete {
      width: 36px;
      height: 36px;
      margin: 0 0 0 16px !important;
      background-color: map-get($theme-colors, "danger") !important;
      img {
        width: 22px;
        margin-top: -4px;
      }
    }
  }
  .card {
    box-shadow: none;
    margin-bottom: 0;
    .card-body {
      padding: 0;
      .row {
        .col-md-6 {
          border-bottom: 1px solid map-get($theme-colors, "border");
          &.border-right {
            border-right: 1px solid map-get($theme-colors, "border");
          }
          .cont-version-info {
            padding: 20px 16px;
            .form-group {
              margin-bottom: 0;
              label {
                font-size: 16px;
                color: map-get($theme-colors, "primary");
              }
              input {
                border: 0;
                padding: 0;
              }
              small {
                margin-top: 0;
              }
              ul {
                font-weight: 600;
                padding-left: 16px;
                li {
                  background-color: map-get($theme-colors, "white");
                  .badge {
                    background-color: map-get($theme-colors, "warning");
                    margin-left: 16px;
                    padding: 6px 8px;
                    font-size: 10px;
                  }
                }
              }
            }
          }
        }
      }
      .footer-edit {
        background-color: map-get($theme-colors, "grey");
        padding: 8px 16px;
        a {
          p {
            margin-bottom: 0;
            color: map-get($theme-colors, "danger");
            font-weight: 600;
            transition: 0.2s;
            .btn-edit {
              background: inherit;
              background-color: map-get($theme-colors, "warning");
              font-size: 16px;
              padding: 8px 12px !important;
              margin-top: 0;
              margin-right: 10px;
            }
          }
          &:hover {
            p {
              opacity: 0.6;
            }
            text-decoration: none;
          }
        }
      }
    }
  }
}

.badge-published {
  background-color: #86d873;
  margin-left: 16px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.badge-outdated {
  background-color: map-get($theme-colors, "border");
  margin-left: 16px;
  padding-left: 1rem;
  padding-right: 1rem;
}
